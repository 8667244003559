import React from "react";
import {Link} from "gatsby";
import Icon from "./Icon";
import {themeDark} from "../page-styles/landingpage.css";
import ThemeBox from "./bricks/ThemeBox";
import {Box, XCol, XRow} from "./xui/Box";
import cx from "./xui/cx";
import {uiStyles} from "./xui/xui.css";

const ActiveAwareLink = ({className, href, ...p}) =>
  href ? (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      className={cx(className, uiStyles.color.body)}
      rel="noopener noreferrer"
      href={href}
      {...p}
    />
  ) : (
    <Link
      getProps={({isCurrent}) => ({
        className: cx(
          className,
          ...(isCurrent
            ? [
                uiStyles.color.primary,
                uiStyles.borderBottomWidth[1],
                uiStyles.borderColor.primaryButton,
              ]
            : [uiStyles.color.body])
        ),
      })}
      {...p}
    />
  );

const NavLink = props => <Box as={ActiveAwareLink} hover_color="secondary" size="sm" {...props} />;
const LinkCol = ({children}) => (
  <XCol sp={3} align="start">
    {children}
  </XCol>
);

const FollowLink = ({children, href}) => (
  <Box
    as="a"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    color="purple400"
    hover_color="purple200"
  >
    {children}
  </Box>
);

export const SocialLinks = () => (
  <XRow sp={3}>
    <FollowLink href="https://discord.gg/9dPXv6T" isDiscord>
      <Icon.Discord alt="Discord" title="Join our Discord Server" />
    </FollowLink>
    <FollowLink href="https://twitter.com/codecks_io">
      <Icon.Twitter alt="Twitter" title="Codecks on Twitter" />
    </FollowLink>
    <FollowLink href="https://www.instagram.com/codecks_io/">
      <Icon.Instagram alt="Instagram" title="Codecks on Instagram" />
    </FollowLink>
    <FollowLink href="https://www.facebook.com/codecks.io/">
      <Icon.Facebook alt="Facebook" title="Codecks on Facebook" />
    </FollowLink>
    <FollowLink href="https://www.youtube.com/codecks">
      <Icon.Youtube alt="YouTube" title="Codecks on YouTube" />
    </FollowLink>
    <FollowLink href="https://www.linkedin.com/company/codecks">
      <Icon.Linkedin alt="LinkedIn" title="Codecks on LinkedIn" />
    </FollowLink>
  </XRow>
);

const Footer = () => {
  return (
    <ThemeBox theme={themeDark} bg="purple900" py={7} sp={7}>
      <XCol align="start" sm_align="center">
        <Box grid sp={7} gridColumns={5} sm_gridColumns={2}>
          <LinkCol>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/features/">Features</NavLink>
            <NavLink to="/community/">Community</NavLink>
            <NavLink to="/blog/">Blog</NavLink>
            <NavLink to="/game-production-podcast/">Podcast</NavLink>
          </LinkCol>
          <LinkCol>
            <NavLink to="/showcase/">Showcase</NavLink>
            <NavLink href={`https://open${process.env.GATSBY_COOKIE_DOMAIN}`}>Open Decks</NavLink>
            <NavLink href={`https://manual${process.env.GATSBY_COOKIE_DOMAIN}`}>Manual</NavLink>
            <NavLink to="/steam-bot/">Steam Bot</NavLink>
          </LinkCol>
          <LinkCol>
            <NavLink to="/about/">About</NavLink>
            <NavLink to="/pricing/">Pricing</NavLink>
            <NavLink to="/faq/">FAQ</NavLink>
            <NavLink href="/status/">Status</NavLink>
          </LinkCol>
          <LinkCol>
            <NavLink to="/changelog/">Changelog</NavLink>
            <NavLink href={`https://open${process.env.GATSBY_COOKIE_DOMAIN}/codecks-roadmap`}>
              Roadmap
            </NavLink>
            <NavLink href="mailto:hello@codecks.io">Contact</NavLink>
            <NavLink href={`https://manual${process.env.GATSBY_COOKIE_DOMAIN}/api`}>API</NavLink>
          </LinkCol>
          <LinkCol>
            <NavLink to="/imprint/">Imprint</NavLink>
            <NavLink href="https://maschinen-mensch.com/press/codecks/">Press Kit</NavLink>
            <NavLink to="/tos/">ToS</NavLink>
            <NavLink to="/privacy/">Privacy</NavLink>
          </LinkCol>
        </Box>
      </XCol>
      <XRow align="center" md_align="center" md_col sp={5}>
        <XRow sp={2} align="center">
          <Box size="lg" color="body">
            For Game Devs - by Game Devs. Made in Berlin
          </Box>
          <Box
            as="img"
            alt="German flag"
            forwardProps={{width: 32, height: 24}}
            src={require("../imgs/flag-de.svg")}
            rounded="sm"
            borderWidth="1"
            borderColor="bgBorder"
            height="1"
            width="auto"
          />
        </XRow>
        <XRow sp={3} ml="auto" md_ml={0} align="center">
          <Box color="body" size="md" relative top="-1px">
            Find Codecks on
          </Box>
          <SocialLinks />
        </XRow>
      </XRow>
    </ThemeBox>
  );
};

export default Footer;
